import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Layout from "../components/layout";
import Seo from "../components/seo";


export default function Home() {
  const data = useStaticQuery(graphql`
    query {
      contentfulZelfliefde(contentful_id: { eq: "6gQ1rp5rmJFQywYhA4ScKB" }) {
        id
        body {
          json
        }
        title
        zelfliefdeImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
          description
        }
        backgroundImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `);

  const { title, body, zelfliefdeImage, backgroundImage } = data.contentfulZelfliefde;

  return (
    <Layout>
      <Seo
        title="Zelfliefde"
        description="Leer van jezelf te houden"
      />
      {/*<BackgroundImage
          fluid={backgroundImage.fluid}
          style={{
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed"
        }}>*/}
      <Grid
        container
        spacing={0}
        alignItems="flex-start"
        justify="space-between"
        style={{ marginBottom: "4rem" }}
      >
      <Grid item xs={12}>
          <div className="textStyleTitleZelfliefde">
            {title}
          </div>
        </Grid>
       {/*<Grid item xs={12} md={12}>
          <Paper elevation={0} style={{ width: "100%", height: "50vh" }}>
            <Img
              fluid={zelfliefdeImage.fluid}
              fadeIn
              alt={zelfliefdeImage.description}
              style={{ width: "100%", height: "100%" }}
              imgStyle={{ objectFit: 'contain' }}
            ></Img>
          </Paper>
        </Grid>*/}

        
        <Grid item xs={12} md={12}>
          <Typography component="div" color="textPrimary" align="center">
            {documentToReactComponents(body.json)}
          </Typography>
        </Grid>
        <Grid item xs={false} md={1}></Grid>
      </Grid>
      {/*</BackgroundImage>*/}
    </Layout>
  );
}
